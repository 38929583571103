export default async response => {
  if (response.ok) return;
  let error;
  try {
    const status = await response.json();
    error = Object.assign(new Error(status.error), { response, status });
  } catch (e) {
    error = Object.assign(new Error(response.statusText), { response });
  } finally {
    throw error;
  }
}