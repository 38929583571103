import _ from 'babel-polyfill'
import fetch from 'isomorphic-fetch'
import checkStatus from './check-status'

window.setupLoginPage = () => {
  const formBlock = document.querySelector("form.login-block");
  const emailInput = document.querySelector("#inputEmail");
  const passwordInput = document.querySelector("#inputPassword");
  const submitButton = document.querySelector("#loginButton");
  const retrievePassword = document.querySelector("#retrievePassword");
  const errorMessage = document.querySelector('.error');

  const setLoading = (loading) => {
    formBlock.classList.toggle('loading', loading);
  }

  submitButton.addEventListener('click', async (event) => {
    event.preventDefault();

    setLoading(true);
    errorMessage.textContent = "";

    try {
      const status = await authenticate({
        email: emailInput.value,
        password: passwordInput.value
      });
      formBlock.classList.add('done');
      // it may seem dumb to delay the user 500ms before they get redirected, but it gives them some
      // warning of what's about to happen, and that their login succeeded
      setTimeout(() => {
        window.location = '/';
      }, 500);
    } catch (e) {
      console.error(e);
      errorMessage.textContent = e.message;
      setLoading(false);
    }
  }, false);

  const authenticate = async (user) => {
    const response = await fetch('/login', {
      credentials: 'include',
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
      }
    })

    await checkStatus(response);
    return await response.json();
  }
}

window.setupSetPasswordPage = () => {
  const formBlock = document.querySelector("form.login-block");
  const passwordInput = document.querySelector("#password");
  const passwordAgainInput = document.querySelector("#passwordAgain");
  const submitButton = document.querySelector("#submitButton");
  const errorMessage = document.querySelector('.error');

  submitButton.addEventListener('click', async (event) => {
    event.preventDefault();

    formBlock.classList.toggle("loading", true);
    errorMessage.textContent = "";

    const password = passwordInput.value;
    const passwordAgain = passwordAgainInput.value;

    if (password !== passwordAgain) {
      errorMessage.textContent = "Passordene må være like";
      return;
    }

    try {
      const response = await fetch('', {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({ password, passwordAgain }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
      await checkStatus(response);
      formBlock.classList.add('done');
      // it may seem dumb to delay the user 500ms before they get redirected, but it gives them some
      // warning of what's about to happen, and that their login succeeded
      setTimeout(() => {
        window.location = '/login';
      }, 500);
    } catch (e) {
      console.error(e);
      errorMessage.textContent = e.message;
      formBlock.classList.toggle("loading", false);
    }
  }, false);
}
